import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import { Button } from "antd";

const Pagination = ({
	total,
	currentPage,
	handleNextPage,
	handlePrevPage,
	perPage,
	label = "Orders",
	totalPax
}: {
	total: number;
	currentPage: number;
	handlePrevPage: () => void;
	handleNextPage: () => void;
	perPage: number;
	label?: string;
	totalPax?: number;
}) => {
	return (
		<div className="w-full px-px h-12 pl-2 py-2.5 bg-white border-t border-slate-300 justify-between items-center inline-flex">
			{!!total && (
				<div className="w-[547px] h-[15px] justify-start items-center gap-2 inline-flex">
					<span className="text-gray-700 text-[11px] font-semibold font-['Inter'] capitalize leading-[15px]">
						Page {currentPage}
						<span className="text-gray-500 text-[11px] font-medium font-['Inter'] capitalize leading-[15px]">
							/{Math.ceil(total / perPage)}
						</span>
					</span>

					<div className="text-gray-500 text-[11px] font-normal font-['Inter'] capitalize leading-[15px]">
						|
					</div>
					<div className="text-gray-600 text-[11px] font-medium font-['Inter'] capitalize leading-[15px]">
						{total} {label}
					</div>
					{!!totalPax && (
						<div className="text-gray-600 text-[11px] font-medium font-['Inter'] capitalize leading-[15px]">
							{totalPax} Pax
						</div>
					)}
				</div>
			)}

			<div className="ml-auto justify-start items-start gap-2 flex">
				<Button
					type="default"
					className="!h-6"
					disabled={
						currentPage === 1 ||
						(currentPage === 1 && total === 0) ||
						(currentPage === 1 && total <= perPage)
					}
					onClick={handlePrevPage}>
					<div className="flex items-center gap-1 text-gray-500">
						<CaretLeft className="text-gray-400 inline-flex" />
						<span>Prev</span>
					</div>
				</Button>
				<Button
					type="default"
					className="!h-6"
					disabled={
						total === 0 || (total > 0 && total <= perPage) || (total > 0 && total <= perPage * currentPage)
					}
					onClick={handleNextPage}>
					<div className="flex items-center gap-1 text-gray-500">
						<span>Next</span>
						<CaretRight className="text-gray-400 inline-flex" />
					</div>
				</Button>
			</div>
		</div>
	);
};

export default Pagination;
