const ENVs = ["production", "staging", "development"] as const;
type TEnv = (typeof ENVs)[number];

let ENV: TEnv =
	window.location.host === "internal.stampmyvisa.com"
		? "production"
		: window.location.host === "qa.internal.stampmyvisa.com"
			? "staging"
			: "development";

ENV = ENVs.filter((_) => _ === localStorage.getItem("env") ?? "")?.[0] ?? ENV;
ENV = "staging";
console.log("🚀 ~ Environment:", ENV);
const isProduction = ENV === ("production" as TEnv);

const getENV = (map: Record<TEnv, string>) => {
	const val = map[ENV as keyof typeof map];
	if (val) return val;
	console.warn("🚀 ~ Environment:", ENV, "not found in map", map);
	return null;
};

let BIZ_URL_MAP = {
	production: "https://stampmyvisa.com",
	staging: "https://qa.stampmyvisa.com",
	development: "https://dev.stampmyvisa.com"
};

let API_BASE_URL_MAP = {
	production: "https://api.live.stampmyvisa.com",
	staging: "https://api.qa.stampmyvisa.com",
	development: "https://api.play.stampmyvisa.com"
};

let MIXPANEL_TOKEN_MAP = {
	production: "557abd2889eb4731b40794fea7b4cff2",
	staging: "519da2911b57bd0ed7eb4e9f6ad9c634", // SAME AS development
	development: "519da2911b57bd0ed7eb4e9f6ad9c634"
};

//SAME FOR ALL ENVIRONMENTS
let SENTRY_DSN_MAP = {
	production: "https://34edeacdbdf23ec7efaf30932ab74e90@o4504384688750592.ingest.us.sentry.io/4507769436700672",
	staging: "https://34edeacdbdf23ec7efaf30932ab74e90@o4504384688750592.ingest.us.sentry.io/4507769436700672",
	development: "https://34edeacdbdf23ec7efaf30932ab74e90@o4504384688750592.ingest.us.sentry.io/4507769436700672"
};

let API_BASE_URL = getENV(API_BASE_URL_MAP) || "http://localhost:3001";
let BIZ_URL = getENV(BIZ_URL_MAP) || "http://localhost:3001";
let MIXPANEL_TOKEN = getENV(MIXPANEL_TOKEN_MAP) || "none";
let SENTRY_DSN = getENV(SENTRY_DSN_MAP) || "none";
let CDN_URL = "https://d1ds5rzn7liqmq.cloudfront.net";

export { API_BASE_URL, BIZ_URL, CDN_URL, ENV, MIXPANEL_TOKEN, SENTRY_DSN, isProduction };
