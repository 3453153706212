import { SVGProps } from "react"
const Search = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={20}
		height={20}
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M17.5 17.5L14.5834 14.5833M16.6667 9.58333C16.6667 13.4954 13.4954 16.6667 9.58333 16.6667C5.67132 16.6667 2.5 13.4954 2.5 9.58333C2.5 5.67132 5.67132 2.5 9.58333 2.5C13.4954 2.5 16.6667 5.67132 16.6667 9.58333Z"
			strokeWidth={1.66667}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)
export default Search
